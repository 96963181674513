<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        variant="tertiary"
        color="grey"
        size="xs"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-20 color-grey"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.flag"
        select-close
        @select="action.action(logs.operationId)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 stroke-icon--grey"
            :data="require(`@icons/${action.icon}.svg`)"
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions } from 'vuex';
import { fileSaver } from '@/mixins/webAPI';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { addCodeToMessage } from '@/api/helpers/errorRegistry';

export default {
  name: 'LogsActionsDropdown',
  mixins: [fileSaver, modal],
  props: {
    actions: {
      type: Number,
      required: true
    },
    logs: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.Project.Download'),
          icon: 'download',
          action: this.handleDownloadLogs
        },
        {
          flag: 1,
          label: this.$t('Web.Project.Delete'),
          icon: 'delete',
          action: this.requestDeleteLogs
        }
      ]
    };
  },
  computed: {
    allowedActions() {
      return this.actionsConfig.filter(action => this.actions & 1 << action.flag);
    }
  },
  methods: {
    ...mapActions({
      downloadLogs: 'logs/downloadLogs',
      deleteLogs: 'logs/deleteLogs'
    }),
    handleDownloadLogs(id) {
      this.saveFile(this.downloadLogs.bind(this, { id }));
    },
    requestDeleteLogs(id) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.ActionConfirm.TitleDeleteLog', { 1: '' }),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: this.handleDeleteLogs.bind(this, id)
      });
    },
    async handleDeleteLogs(id) {
      try {
        await this.deleteLogs({ id });
      } catch (e) {
        if (!e?.message) {
          return this.$notify({
            type: 'error',
            text: addCodeToMessage(this.$t('Web.UpdateData.DeleteLogsError'), e?.code),
            duration: -1
          });
        }

        this.$notify({
          type: 'error',
          text: e?.message,
          duration: -1
        });
      }
    }
  }
};
</script>
