<template>
  <SubPageWrapper
    width="full"
    relative
  > 
    <template #loader>
      <SlOverlay
        :show="isLoading"
        filled
      />
    </template>
    <ContentBlock>
      <div class="subpage__title heading-3-sb grey-100">
        {{ $t('Web.UpdateData.ImportLogs') }}
      </div>
      <div class="subpage__table-wrapper">
        <div class="subpage__table">
          <SlTable
            id="log-list"
            :headers="headers"
            header-unique-key="key"
            header-name-key="name"
            :rows="rows"
            unique-key="operationId"
            :initial-col-sizes="initialColSizes"
            :row-height="40"
            :max-height="1100"
            :value-parser="parseValue"
            num-col
            list
          >
            <template #Status="slotScope">
              <div class="sl-table__cell-text">
                <SlBadge :variant="getVariant(slotScope.cellValue.val)">
                  <template #text>
                    {{ getStatusLabel(slotScope.cellValue.val) }}
                  </template>
                </SlBadge>
              </div>
            </template>
            <template #Timestamp="slotScope">
              <div class="sl-table__cell-text">
                {{ formatFullTime(slotScope.cellValue.val) }}
              </div>
            </template>
            <template #ActionsFgs="slotScope">
              <LogsListActionsDropdown
                v-if="slotScope.cellValue"
                :actions="slotScope.cellValue.val"
                :logs="slotScope.row"
              />
            </template>

            <template
              v-if="noAccess"
              #no-data
            >
              <SlNoData>
                <template #title>
                  {{ $t('Web.Users.NoAccess') }}
                </template>
                <template #text>
                  {{ $t('Web.Error.WrongTenant') }}
                </template>
              </SlNoData>
            </template>
          </SlTable>
        </div>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LogsListActionsDropdown from '@/components/Logs/LogsActionDropdown.vue';
import { componentNames } from '@/config/app.config';
import { logsStatuses } from '@/config/project/updateData.config';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';
import { formatFullTime } from '@/helpers/date/date';

export default {
  name: 'LogsPage',
  components: {
    LogsListActionsDropdown
  },
  data() {
    return {
      interval: null,
      LOGS_INTERVAL_TIMEOUT: 10000,
      isLoading: false,
      noAccess: false,
      initialColSizes: ['300px', '300px', '150px', '250px', '250px', '40px'],
      variantStatus: {
        [logsStatuses.SUCCESS]: 'accent-green',
        [logsStatuses.FAILED]: 'accent-red',
        [logsStatuses.PENDING]: 'primary'
      },
      statusLabel: {
        [logsStatuses.SUCCESS]: this.$t('Web.UpdateData.SuccessStatus'),
        [logsStatuses.FAILED]: this.$t('Web.UpdateData.FailedStatus'),
        [logsStatuses.PENDING]: this.$t('Web.UpdateData.PendingStatus')
      },
      formatFullTime
    };
  },
  computed: {
    ...mapState({
      table: (state) => state.logs.logs_list
    }),
    headers() {
      return this.table?.header || [];
    },
    rows() {
      return this.table?.body || [];
    }
  },
  async beforeMount() {
    this.interval = setInterval(this.fetchLogsList, this.LOGS_INTERVAL_TIMEOUT);

    try {
      this.isLoading = true;

      await sendRequests(componentNames.LOGS_PAGE);
    } catch {
      clearInterval(this.interval);
      
      this.noAccess = true;
    } finally {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    this.noAccess = false;
    
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions({
      fetchLogsList: 'logs/fetchLogsList'
    }),
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val ?? '';
    },
    getVariant(status) {
      return this.variantStatus[status] || '';
    },
    getStatusLabel(status) {
      return this.statusLabel[status] || '';
    }
  }
};

</script>

<style lang="scss" scoped>
.subpage__table-wrapper {
  height: calc(100% - 40px);
}

::v-deep {
  .sl-table {
    max-width: 100%;
  }
}
</style>